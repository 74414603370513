<template>
  <form class="modal-inner-container">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('email.confirmation_sent')}}
      </div>

      <div class="text-n-md font-medium mt-n-5xl">
        {{$t('email.twofa_confirmation_email')}}
      </div>
    </div>

    <ButtonV2
      @onClick="hideModal"
      :label="$t('navigation.got_it')"
      testId="btn-got-it"
      class="mt-n-5xl"
      wide
    />
  </form>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'TwoFactorAuthenticationEmailVerification',
    components: {
      ButtonV2,
    },
  };
</script>
